$(document).ready(function () {

	////////////
	// MODALS //
	////////////

	// SOMOS EXPERIENCIA FORM MODAL
	const somosExperienciaModalButton = $('.somos-experiencia-block .btn-modal-experiencia');
	const somosExperienciaModal = $('#somosExperienciaModal');
    const somosExperienciaModalContent = $('#somosExperienciaModal .modal-content');

    // Botones para cambiar entre contenido modal
    const somosExperienciaChangeContentButton = $('#somosExperienciaModal .test-ok');

    // Cambiar contenido modal
    function changeContentSomosExperienciaModal(content) {
        // Ocultar todo el contenido
        somosExperienciaModalContent.hide();
        // Mostrar solamente el nuevo contenido
        $('#somosExperienciaModal .modal-content[data-modal-content="'+content+'"]').show();
    }

	// Click en botón para abrir modal
	somosExperienciaModalButton.on('click', function(e) {
		$('body').addClass('modal-open');
		somosExperienciaModal.addClass('modal-open');
	});

    // Click en botón para cambiar contenido modal
    somosExperienciaChangeContentButton.on('click', function(e) {
		e.preventDefault();

        let newContent = $(this).data('modalContent');
        changeContentSomosExperienciaModal(newContent);
    });

	/////////////
	// SLIDERS //
	/////////////

	// HOME HEADER
	const homeSlider = $(".home-slider");

	// NUESTRAS PROMOCIONES
	const promotionsSlider = $(".promociones-block .promociones-slider");
	const promotionsNumber = $(".promociones-block .promociones-slider .promocion").length;

	// TESTIMONIOS BLOCK
	const testimoniosSlider = $(".testimonios-block .testimonios");

	// Comprobar HOME HEADER
	function checkHomeSlider() {
		if (homeSlider.length > 0) {
			if (homeSlider.hasClass('slick-initialized')) {
				homeSlider.slick('resize');
			} else {
				homeSlider.slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
					arrows: false,
					speed: 600,
					autoplay: true,
					autoplaySpeed: 5000,
					pauseOnHover: false,
					fade: true
				});
			}
		}
	}

	// Comprobar NUESTRAS PROMOCIONES BLOCK
	function checkPromocionesSlider() {
		if (promotionsSlider.length > 0) {
			if (promotionsSlider.hasClass('slick-initialized')) {
				promotionsSlider.slick('resize');
			} else {
				promotionsSlider.slick({
					slidesToShow: promotionsNumber / 2,
					slidesToScroll: 1,
					infinite: false,
					dots: false,
					arrows: true,
					speed: 600,
					autoplay: false,
					rows: 1,
					responsive: [
						{
							breakpoint: 768,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
								adaptiveHeight: true,
								infinite: true
							}
						},
					]
				});
			}
		}
	}

	// Comprobar TESTIMONIOS BLOCK
	function checkTestimoniosSlider() {
		if (testimoniosSlider.length > 0) {
			if (testimoniosSlider.hasClass('slick-initialized')) {
				testimoniosSlider.slick('resize');
			} else {
				testimoniosSlider.slick({
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					dots: true,
					arrows: false,
					speed: 600,
					autoplay: true,
					autoplaySpeed: 5000,
					responsive: [
						{
							breakpoint: 768,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
								adaptiveHeight: true,
							}
						},
					]
				});
			}
		}
	}

	////////////
	// EVENTS //
	////////////

	$(window).on('load resize', function(e) {
		checkHomeSlider();
		checkPromocionesSlider();
		checkTestimoniosSlider();
	});


	//////////
	// TEST //
	//////////

	// Botón en formulario de la cabecera de HOME para simular una búsqueda e ir a la página de que no hay resultados
	$('.test-no-result').on('click', function(e) {
		e.preventDefault();
		window.location.href = '/promociones-no-result.html';
	});
});